<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="其它收入审核"> </el-page-header>
    </div>
    <div class="table" style="margin-bottom: 60px;">
      <el-table :data="tableData.list" stripe v-loading="loading">
        <template v-for="(item, index) in columnList">
          <el-table-column
            align="center"
            :key="index"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
            :filters="item.filed_status"
          >
            <template slot-scope="scope">
              <template>
                {{
                  scope.row[item.field_alias]
                    ? scope.row[item.field_alias]
                    : "--"
                }}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="buttons">
      <el-button type="primary">审核通过</el-button>
      <el-button type="primary" plain @click="dialogVisible = true">审核不通过</el-button>
    </div>
    <el-dialog
      title="审核不通过原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="form">
        <el-form-item label="原因说明" required="">
          <el-input type="textarea" :rows="3" placeholder="请输入审核不通过原因说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dialogVisible: true,
      loading: false,
      columnList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "单据编号", field_alias: "create_time" },
        { field_text: "订单类型", field_alias: "hierarchy_name" },
        { field_text: "单据日期", field_alias: "num" },
        { field_text: "门店", field_alias: "gold_weight" },
        { field_text: "客户", field_alias: "main_stone_weight" },
        { field_text: "业务员", field_alias: "weight" },
        { field_text: "款项名称", field_alias: "price_tag" },
        { field_text: "收款金额", field_alias: "total_cost_price" },
        { field_text: "备注", field_alias: "index2" },
        { field_text: "审核状态", field_alias: "create_time2" },
        { field_text: "创建人", field_alias: "hierarchy_name2" },
        { field_text: "创建时间", field_alias: "num2" },
        { field_text: "审核人", field_alias: "gold_weight2" },
        { field_text: "审核时间", field_alias: "main_stone_weight2" },
      ],
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  methods: {
    goBack() {
      this.$emit("onBack");
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.buttons {
  display: flex;
  background: white;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
}
</style>
