<template>
  <div>
    <el-button v-show="show" type="primary" @click="handlePrint" :loading="loading">打印</el-button>
    <el-dialog
      title="打印预览（A4纸）"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="80%"
    >
      <div class="box">
        <div class="paper" id="paper" style="font-size: 12px; font-family: '宋体'; width: 21cm;">
          <div
            class="content"
            style="width: 100%; position: relative; background: white"
          >
            <div
              class="title"
              style="
                text-align: center;
                line-height: 40px;
                font-size: 20px;
                font-weight: bold;
              "
            >
              {{ title }}
            </div>
            <div
              class="txt"
              style="display: flex; flex-wrap: wrap"
              v-for="(item, index) in printData.top"
              :key="index"
            >
              <div
                class="item"
                :style="{
                  width: _.width,
                  display: 'flex',
                  marginRight: '1%',
                  marginTop: '20px',
                }"
                v-for="(_, _index) in item"
                :key="_index"
              >
                <div>{{ _.txt }}：</div>
                <div
                  class="border"
                  style="
                    flex: 1;
                    border-bottom: 1px solid black;
                    word-break: break-all;
                    position: relative;
                  "
                >
                  {{ _.value }}
                  <!-- <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 1px; background: black; transform: scaleY(0.5); -webkit-transform: scaleY(0.5);"></div> -->
                </div>
              </div>
            </div>
            <table
              v-if="tableType === 1"
              class="my-table"
              style="
                border-left: 1px solid black;
                margin: 0 auto;
                margin-top: 20px;
              "
            >
              <tr class="header" style="display: flex;">
                <template v-for="item in printRows">
                  <th
                    class="cell"
                    :key="item.field_alias"
                    :style="{
                      background: '#EAEDED',
                      borderTop: '1px solid black',
                      width: item.width?(item.width / 10) + 'cm':(item.t_width / 100) + 'cm',
                      minHeight: '0.6cm',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      borderRight: '1px solid black',
                      borderBottom: '1px solid black',
                      fontWeight: 'bold',
                    }"
                  >
                    {{ item.field_text }}
                  </th>
                </template>
              </tr>
              <tr
                class="row"
                style="display: flex"
                v-for="(item, index) in printData.list"
                :key="index"
              >
                <td
                  class="cell"
                  :key="cell.field_alias"
                  v-for="cell in printRows"
                  :style="{
                    width:cell.width?(cell.width / 10) + 'cm':(cell.t_width / 100) + 'cm',
                    minHeight: '0.6cm',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') || (cell.field_alias === 'remark') || (cell.field_alias === 'pay_channel_name')? 'flex-start' : cell.field_alias === 'index' ? 'center' : 'flex-end',
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black',
                    wordBreak: 'break-all',
                    fontWeight: item.index === '合计' ? 'bold' : '',
                    flexDirection: ((item.info && cell.field_alias !== 'index') || (cell.is_goods && index !== printData.list.length - 1)) ? 'column': '',
                  }"
                >
                  <!-- 修改单的情况 合并 -->
                  <template v-if="item.info && cell.field_alias !== 'index'">
                    <div v-for="(info, infoIndex) in item.info" :key="infoIndex"
                        :style="{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'flex-start' : cell.field_alias === 'edit_sign' ? 'center' : 'flex-end',
                          minHeight: '0.6cm',
                          borderBottom: infoIndex !== 2 ? '1px solid black': '',
                          color: (infoIndex%2 != 0 && info[cell.field_alias] != item.info[infoIndex - 1][cell.field_alias]) ? '#F56C6C' : '',
                        }">
                      <div
                      >
                      <template v-if="item.index !== '合计'">
                        <span v-if="info.edit_sign !== '差异'">
                          {{ info[cell.field_alias] ? info[cell.field_alias] : '--'}}
                        </span>
                        <span v-else>
                          {{ info[cell.field_alias] || info[cell.field_alias] === 0 ? info[cell.field_alias] : '' }}
                        </span>
                      </template>
                      <template v-else>
                        {{ info[cell.field_alias] || info[cell.field_alias] === 0 ? info[cell.field_alias] : '' }}
                      </template>
                      </div>
                    </div>
                  </template>
                  <!-- 订单的情况 合并 -->
                  <template v-else-if="cell.is_goods">
                    <td v-for="(info, infoIndex) in item.goods" :key="infoIndex"
                        :style="{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'flex-start' : cell.field_alias === 'edit_sign' ? 'center' : 'flex-end',
                          minHeight: '0.6cm',
                          borderBottom: infoIndex === item.goods.length - 1 ? '': '1px solid black',
                          wordWrap:'break-word'
                        }">
                      {{ info[cell.field_alias] ? info[cell.field_alias] : '--'}}
                    </td>
                  </template>
                  <template v-else>
                    <div>
                      {{ item[cell.field_alias] }}
                    </div>
                  </template>
                </td>
              </tr>
            </table>
            <table v-else
            style="margin-top: 20px; border: 1px solid black;"
            cellspacing="0"
            >
              <tr style="background: #EAEDED; font-weight: bold;">
                <template v-for="(item, index) in printRows">
                  <th
                    :style="{
                      borderBottom: '1px solid black',
                      borderRight: index === printRows.length - 1 ? '' : '1px solid black',
                    }"
                    :key="item.field_alias"
                  >
                    {{ item.field_text }}
                  </th>
                </template>
              </tr>
              <tr
                v-for="(item, index) in printData.list"
                :key="index"
              >
                <td
                  v-for="(cell, cellIndex) in printRows"
                  :style="{
                    width: cell.width / 10 + 'cm',
                    borderBottom: index === printData.list.length - 1 ? '' : '1px solid black',
                    borderRight: cellIndex === printRows.length - 1 ? '' : '1px solid black',
                    minHeight: '0.6cm',
                    wordBreak: 'break-all',
                    fontWeight: index === printData.list.length - 1 ? 'bold' : '',
                    textAlign: (cell.field_alias === 'index') || (cell.field_alias === 'create_time') ? 'center' : (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'left' : 'right'
                  }"
                  :rowspan="!cell.is_goods ? item.rowspan : ''"
                  :key="cell.field_alias"
                  v-if="cell.is_goods || item.rowspan || index === printData.list.length - 1"
                >
                  <template>
                    {{ item[cell.field_alias] }}
                  </template>
                </td>
              </tr>
            </table>
            <div
              class="txt"
              style="display: flex; flex-wrap: wrap"
              v-for="(item, index) in printData.bottom"
              :key="'txt' + index"
            >
              <div
                class="item"
                :style="{
                  width: _.width,
                  display: 'flex',
                  marginRight: '1%',
                  marginTop: '20px',
                }"
                v-for="(_, _index) in item"
                :key="_index"
              >
                <div>{{ _.txt }}：</div>
                <div
                  class="border"
                  style="
                    position: relative;
                    flex: 1;
                    border-bottom: 1px solid black;
                    word-break: break-all;
                  "
                >
                  {{ _.value }}
                  <!-- <div style="position: absolute; bottom: 0; left: 0; right: 0; height: 1px; background: black; transform: scaleY(0.5);"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" v-print="printObj" @click.native="setPrintNums">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import numeral from "numeral";
export default {
  data() {
    return {
      printObj: {
        id: "paper",
        popTitle: "E店宝",
      },
    };
  },
  props: {
    dialogVisible: Boolean,
    show: Boolean,
    printRows: Array,
    printData: Object,
    title: String,
    loading: Boolean,
    tableType: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    printRows(val) {
      console.log(val);
    },
  },
  methods: {
    setPrintNums(){
      this.$emit('setPrintNums')
    },
    handlePrint() {
      this.$emit('onPrint');
    },
    handleClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  overflow-y: auto;
  height: 500px;
  display: flex;
  justify-content: center;
  .paper {
    font-size: 12px;
    width: 21cm;
    // height: 29.7cm;
    .title {
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
    }
    .txt {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 24%;
        display: flex;
        margin-right: 1%;
        margin-top: 20px;
        .border {
          // border-bottom: 1px solid black;
          flex: 1;
        }
      }
    }
    .my-table {
      border-left: 1px solid black;
      margin: 0 auto;
      margin-top: 20px;
      .header,
      .row {
        display: flex;
      }
    }
  }
}
</style>
