import { post } from '@/utils/request';

const getListReq = (cond) => post({
  url: '/store/goods.stock.History/list',
  data: {
    ...cond,
  },
});

// 历史库存数据列表
const getHistoryListReq = (cond) => post({
  url: '/store/goods.stock.History/desc',
  data: {
    ...cond,
  },
});

// 历史库存单打印数据
const historyInvPrintReq = (cond) => post({
  url: '/store/goods.stock.History/descPrint',
  data: {
    ...cond,
  },
});

// 门店列表
const getMerchantListReq = (data) => {
  return post({
      url: "/store/common.select/getMerchantList",
      data: data
  })
}

// 历史库存导出
const exportReq = (data) => {
  return post({
      url: "/store/goods.stock.History/export",
      data: data
  })
}

export {
  exportReq,
  getListReq,
  getHistoryListReq,
  historyInvPrintReq,
  getMerchantListReq,
};
